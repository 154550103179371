var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.I18nFn(`Financial Data Options`))+"\n      ")]),_vm._v(" "),_c('v-card-text',[_c('v-switch',{attrs:{"label":`${_vm.$Amplify.I18n.get(
            'Hide All Ticker Share Prices (Business Owner Mode)'
          )}: ${_vm.$Amplify.I18n.get(_vm.hidePrice)}`,"color":"primaryAction"},model:{value:(_vm.hidePrice),callback:function ($$v) {_vm.hidePrice=$$v},expression:"hidePrice"}}),_vm._v(" "),_c('v-switch',{attrs:{"label":`${_vm.$Amplify.I18n.get(
            'Hide Ticker Price Chart after navigating away from company overview'
          )}: ${_vm.$Amplify.I18n.get(_vm.autoHidePriceChart)}`,"color":"primaryAction"},model:{value:(_vm.autoHidePriceChart),callback:function ($$v) {_vm.autoHidePriceChart=$$v},expression:"autoHidePriceChart"}}),_vm._v(" "),_c('v-select',{attrs:{"items":_vm.periodYears,"label":_vm.allPeriodsDefault.toString(),"outlined":"","hint":`${_vm.$Amplify.I18n.get(
            'Default Year To Start Financials & Estimates Data'
          )}`,"persistent-hint":""},on:{"change":_vm.allPeriodsDefaultChange}})],1),_vm._v(" "),(false)?_c('v-card-actions',[_c('v-btn',[_vm._v(_vm._s(_vm.I18nFn("Reset Settings")))])],1):_vm._e()],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v("\n        "+_vm._s(_vm.I18nFn("App Layout Options"))+"\n      ")]),_vm._v(" "),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.appLanguages,"label":_vm.defaultAppLang.toUpperCase(),"outlined":"","hint":_vm.$Amplify.I18n.get('Default App Language'),"persistent-hint":""},on:{"change":_vm.appLangChange}}),_vm._v(" "),_c('v-switch',{attrs:{"label":`${_vm.$Amplify.I18n.get(
            'Dark Mode Preview'
          )}: ${_vm.$Amplify.I18n.get(_vm.darkMode)}`,"color":"primaryAction"},model:{value:(_vm.darkMode),callback:function ($$v) {_vm.darkMode=$$v},expression:"darkMode"}}),_vm._v(" "),_c('v-switch',{attrs:{"label":`${_vm.$Amplify.I18n.get(
            'Updated Significant Developments Formatting'
          )}: ${_vm.$Amplify.I18n.get(_vm.sigDevFormat)}`,"color":"primaryAction"},model:{value:(_vm.sigDevFormat),callback:function ($$v) {_vm.sigDevFormat=$$v},expression:"sigDevFormat"}}),_vm._v(" "),_c('v-switch',{attrs:{"label":`${_vm.$Amplify.I18n.get(
            'Remove icons from tabs while researching an equity'
          )}: ${_vm.$Amplify.I18n.get(_vm.denseStockTabs)}`,"color":"primaryAction"},model:{value:(_vm.denseStockTabs),callback:function ($$v) {_vm.denseStockTabs=$$v},expression:"denseStockTabs"}})],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.I18nFn("News Options")))]),_vm._v(" "),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.newsLanguages,"label":_vm.defaultNewsLang,"outlined":"","hint":_vm.$Amplify.I18n.get('Default News Language'),"persistent-hint":""},on:{"change":_vm.newsLangChange}}),_vm._v(" "),(_vm.defaultNewsLang !== 'EN')?_c('v-switch',{attrs:{"label":`${_vm.$Amplify.I18n.get(
            'Include English Stories in News Feeds'
          )}: ${_vm.$Amplify.I18n.get(_vm.includeEnglishNews)}`,"color":"primaryAction"},model:{value:(_vm.includeEnglishNews),callback:function ($$v) {_vm.includeEnglishNews=$$v},expression:"includeEnglishNews"}}):_vm._e()],1)],1)],1),_vm._v(" "),(_vm.dev)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.I18nFn("Subscription")))]),_vm._v(" "),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.tiers,"label":_vm.customGroup,"outlined":"","hint":_vm.$Amplify.I18n.get('test subscription level'),"persistent-hint":""},on:{"change":_vm.devSubChange},model:{value:(_vm.tier),callback:function ($$v) {_vm.tier=$$v},expression:"tier"}})],1),_vm._v(" "),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primaryAction","disabled":!_vm.tier || _vm.tier === _vm.customGroup,"loading":_vm.groupLoading},on:{"click":function($event){return _vm.changeGroup(_vm.tier)}}},[_vm._v("\n          Change Tier\n        ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }